import React from 'react';
import HomeContactLanding from './HomeContactLanding';

const HomeContact = () => {
    return (
        <div>
            <HomeContactLanding></HomeContactLanding>
        </div>
    );
};

export default HomeContact;