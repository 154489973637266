import React from 'react';
import Team from '../Team/Team';
import MeetTeamLanding from './MeetTeamLanding';
 
// import Team from '../Team/Team';
const MeetTeam = () => {
     return (
          <div>
               <MeetTeamLanding></MeetTeamLanding>
             
               <Team></Team>
               
          </div>
     );
};

export default MeetTeam;