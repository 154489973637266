import React from 'react';
import   forzeroFour from "../../assets/404.jpg"
const FourZeroFour = () => {
    return (
        <div>
            <div className=''>
            <img className='mx-auto' src={forzeroFour} alt=""/>
            </div>
        </div>
    );
};

export default FourZeroFour;